const emitter = {
  on (eventName, handler) {
    if (!eventName && !handler) return

    window.addEventListener(eventName, handler)
  },

  remove (eventName, handler) {
    if (!eventName && !handler) return

    window.removeEventListener(eventName, handler)
  },

  emit (eventName, data = {}) {
    if (!eventName) return

    const event = new CustomEvent(eventName, { detail: data })
    window.dispatchEvent(event)
  }
}

export const useEmitter = controller => {
  Object.assign(controller, emitter)
}
