import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect () {
    const { default: TomSelect } = await import('tom-select')
    this.selectInstance = new TomSelect(this.element, {
      plugins: ['remove_button', 'clear_button'],
      hidePlaceholder: true
    })
  }

  disconnect () {
    this.selectInstance?.destroy()
  }
}
