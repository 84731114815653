import Controller from '../desktop/controller'

export default class extends Controller {
  static targets = ['counter']

  connect () {
    this.disableLoading()
    this.action = this.element.action
  }

  handleSubmit (event) {
    event.preventDefault()

    this.enableLoading()
    this.excludeEmptySelections()
    this.element.submit()
  }

  handleReset (event) {
    const { inputName } = event.currentTarget.dataset

    if (inputName) {
      // Local reset
      const selector = `[name="${inputName}"]`
      this.#resetInput(selector)
    } else {
      // Global reset
      this.#resetInput('input')
    }

    this.#update()
  }

  handleChange () {
    this.#update()
  }

  // TODO: maybe need different name
  async #update () {
    const url = this.#buildUrl()

    this.enableLoading()

    try {
      let data = await this.#fetchData(url)

      if (data.request_url) {
        data = await this.#fetchData(data.request_url)
      }

      if (data.jobs_count !== undefined) {
        this.counter = data.jobs_count
      }
    } catch (error) {
      console.log(error)
    }

    this.disableLoading()
  }

  #buildUrl () {
    const formData = new FormData(this.element)
    const searchParams = new URLSearchParams(formData).toString()
    const url = this.action + '?' + searchParams

    return url
  }

  async #fetchData (url = '') {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    const options = {
      method: 'GET',
      headers
    }

    const response = await fetch(url, options)
    const data = await response.json()

    return data
  }

  #resetInput (selector) {
    const inputCheckedElements = [...document.querySelectorAll(selector + ':checked')]
    inputCheckedElements.forEach((inputElement) => { inputElement.checked = false })
  }

  set counter (value = 0) {
    this.counterTargets.forEach((counterTarget) => { counterTarget.textContent = value })
  }
}
