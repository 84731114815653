// Styles
import 'tinymce/skins/ui/oxide/skin.min.css'
import contentCSS from '../entrypoints/editor-content.scss?inline'

const options = {
  content_css: false,
  content_style: contentCSS,
  body_class: 'article-content p-3 p-lg-4',

  plugins: 'anchor autolink link lists quickbars typograph',
  toolbar: 'bold bullist numlist link',
  contextmenu: 'link',

  link_title: false,
  link_target_list: false,
  link_default_target: '_blank',
  quickbars_selection_toolbar: 'bold bullist numlist link',
  quickbars_insert_toolbar: false,

  valid_children: '-h2[strong],-h3[strong],-h4[strong]',

  height: 446,
  menubar: false,
  statusbar: false,
  branding: false,
  resize: false,
  keep_styles: false,
  entity_encoding: 'raw',
  fix_list_elements: true,
  invalid_elements: 'canvas',
  relative_urls: false,
  remove_script_host: false,
  convert_urls: false,
  automatic_uploads: false,
  object_resizing: false,
  typeahead_urls: false,
  skin: false,
  browser_spellcheck: false
}

const editor = {
  async init (element) {
    const { default: tinymce } = await import('tinymce')

    await Promise.all([
      // Core
      import('tinymce/models/dom'),
      import('tinymce/themes/silver/theme.min'),
      import('tinymce/icons/default'),

      // Core plugins
      import('tinymce/plugins/anchor'),
      import('tinymce/plugins/autolink'),
      import('tinymce/plugins/link'),
      import('tinymce/plugins/lists'),
      import('tinymce/plugins/quickbars'),

      // Plugins
      import('../editor-plugins/typograph')
    ])

    const editors = await tinymce.init({
      target: element,
      ...options
    })

    return editors[0]
  }
}

export const useEditor = controller => {
  Object.assign(controller, editor)
}
