import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['outer', 'inner']

  connect () {
    this.#trimInnerHtml(this.outerTarget)
  }

  handleChangeInner ({ target }) {
    const { value } = target
    const cloneElement = this.#getCloneElementByValue(this.outerTarget, value)

    if (cloneElement) {
      cloneElement.checked = target.checked
    } else {
      this.#appendCloneElementWithParent(this.outerTarget, target)
    }
  }

  handleChangeOuter ({ target }) {
    const { value } = target

    if (!this.hasInnerTarget) return

    const cloneElement = this.#getCloneElementByValue(this.innerTarget, value)

    if (cloneElement) {
      cloneElement.checked = target.checked
    }
  }

  #getCloneElementByValue (parent, value) {
    const selector = `[value="${value}"]`
    const elementByValue = parent.querySelector(selector)

    this.#setFullTitle(elementByValue)

    return elementByValue
  }

  #appendCloneElementWithParent (parent, element) {
    const { parentElement } = element
    const cloneElement = parentElement.cloneNode(true)

    this.#setFullTitle(cloneElement)
    parent.append(cloneElement)
  }

  #trimInnerHtml (element) {
    if (element) {
      element.innerHTML = element.innerHTML.trim()
    }
  }

  #setFullTitle (element) {
    const titleElement = element?.querySelector('[data-full-title]')

    if (!titleElement) return

    const fullTitle = titleElement.dataset.fullTitle

    if (fullTitle) {
      titleElement.textContent = fullTitle
    }
  }
}
