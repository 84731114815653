const togglerBodyScrolling = {
  disableBodyScrolling () {
    document.body.classList.add('disabled-scrolling')
  },

  enableBodyScrolling () {
    document.body.classList.remove('disabled-scrolling')
  }
}

export const useTogglerBodyScrolling = controller => {
  Object.assign(controller, togglerBodyScrolling)
}
