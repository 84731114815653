import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }

  async connect () {
    const { default: Modal } = await import('bootstrap/js/dist/modal')
    const modalElement = document.getElementById(this.idValue)
    const modalInstance = Modal.getOrCreateInstance(modalElement)

    if (!modalInstance) return

    modalInstance.toggle()
  }
}
