import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'inputEmail', 'outputEmail']

  async connect () {
    const { default: Modal } = await import('bootstrap/js/dist/modal')
    this.modalInstance = Modal.getOrCreateInstance(this.modalTarget)
  }

  handleSubmit (event) {
    event.preventDefault()

    const { target } = event

    if (target.checkValidity()) {
      const { value } = this.inputEmailTarget
      this.outputEmailTarget.value = value

      this.modalInstance.show()
      target.reset()
    }
  }
}
