import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['loading']

  handleChange (event) {
    event.preventDefault()

    this.enableLoading()

    this.excludeEmptySelections()
    this.element.requestSubmit()
  }

  // Exclude empty selections so that the search url is sent without empty parameters
  excludeEmptySelections () {
    const selectionsElements = [...this.element.querySelectorAll('select')]

    selectionsElements.forEach(selectElement => {
      const { value } = selectElement
      const hasValue = value && value != '[]'

      if (hasValue) return

      selectElement.setAttribute('name', '')
    })
  }

  enableLoading () {
    document.body.classList.add(this.loadingClass)
  }

  disableLoading () {
    document.body.classList.remove(this.loadingClass)
  }
}
