import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect () {
    const { default: Popover } = await import('bootstrap/js/dist/modal')
    this.popoverInstance = Popover.getOrCreateInstance(this.element, {
      container: this.element.parentElement,
      trigger: 'focus',
      html: true,
      template: `
        <div class="popover" role="tooltip">
          <div class="popover-header"></div>
          <a
            role="button"
            class="btn-close btn-close-sm"
            title="close"></a>
          <div class="popover-body"></div>
        </div>
      `
    })
  }
}
