import { Controller } from '@hotwired/stimulus'

// TODO: delete after better :has() support
// https://developer.mozilla.org/en-US/docs/Web/CSS/:has
export default class extends Controller {
  static targets = ['mobileToolbar']

  connect () {
    if (this.hasMobileToolbarTarget) {
      this.element.classList.add('has-mobile-toolbar')
    }
  }
}
