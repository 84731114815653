import { Controller } from '@hotwired/stimulus'
import { useEditor } from '../mixins/useEditor'
import { useSanitizer } from '../mixins/useSanitizer'

export default class extends Controller {
  static targets = ['textarea', 'spinner']

  async connect () {
    await Promise.all([useEditor(this), useSanitizer(this)])

    this.instanceEditor = await this.init(this.textareaTarget)
    this.instanceEditor.on('PastePreProcess', this.#handlePrePaste.bind(this))
    this.instanceEditor.on('GetContent', this.#handleGetContent.bind(this))

    this.#hideSpinner()
  }

  disconnect () {
    this.instanceEditor?.destroy()
  }

  #handlePrePaste (args) {
    args.content = this.sanitizeHtml(args.content)
  }

  #handleGetContent (args) {
    if (args.save) {
      this.textareaTarget.value = this.sanitizeHtml(args.content)
    }
  }

  #hideSpinner () {
    this.spinnerTarget.classList.add('d-none')
  }
}
