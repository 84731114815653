import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  connect () {
    this.selectInstance = new TomSelect(this.element, {
      allowEmptyOption: false,
      closeAfterSelect: true,
      onItemAdd: () => this.selectInstance.blur()
    })
  }

  disconnect () {
    this.selectInstance?.destroy()
  }
}
