const options = {
  allowedTags: ['h2', 'h3', 'h4', 'p', 'a', 'strong', 'ul', 'ol', 'li'],
  allowedAttributes: {
    a: ['href', 'target', 'rel']
  }
}

const sanitizer = {
  async load () {
    const { default: sanitizeHtml } = await import('sanitize-html')
    this.sanitizeInstance = sanitizeHtml
  },
  sanitizeHtml (content) {
    return this.sanitizeInstance(content, options)
  }
}

export const useSanitizer = async controller => {
  await sanitizer.load()
  Object.assign(controller, sanitizer)
}
