import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'label', 'preview', 'trashInput']
  static classes = ['uploaded']
  static values = {
    fallback: String,
    labelDefault: String
  }

  handleInput (event) {
    const [file] = event.target.files
    if (!file) return

    this.#setLoaded(file)
  }

  handleClickTrash (event) {
    event.preventDefault()

    this.#setDeleted()
    this.#setDefault()
  }

  #setLoaded (file) {
    this.trashInputTarget.value = ''
    this.previewTarget.src = URL.createObjectURL(file)
    this.labelTarget.textContent = file.name
    this.element.classList.add(this.uploadedClass)
  }

  #setDefault () {
    this.inputTarget.value = ''
    this.previewTarget.src = this.fallbackValue
    this.labelTarget.textContent = this.labelDefaultValue
    this.element.classList.remove(this.uploadedClass)
  }

  #setDeleted () {
    this.trashInputTarget.value = 'delete'
  }
}
