import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cities']

  async handleChange (event) {
    const countryId = event.currentTarget.value
    const data = await this.#fetchData(countryId)

    this.citiesTarget.innerHTML = data.cities.html

    if (data.cities.success) {
      this.citiesTarget.removeAttribute('disabled')
    } else {
      this.citiesTarget.setAttribute('disabled', '')
    }
  }

  async #fetchData (countryId) {
    const path = '/countries/' + countryId + '/cities'

    const response = await fetch(path)
    const data = await response.json()

    return data
  }
}
