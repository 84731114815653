import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static classes = ['hidden']

  initialize () {
    this.visitedCookieKey = 'cookies_terms_accepted'

    this.classNames = {
      hidden: 'cookie-dialog_hidden'
    }

    this.#handleShow()
  }

  handleClose () {
    this.#close()
    this.#setCookie()
  }

  #handleShow () {
    if (this.#isVisited()) return

    setTimeout(() => this.#show(), 1500)
  }

  #show () {
    this.element.classList.remove(this.classNames.hidden)
  }

  #close () {
    this.element.classList.add(this.classNames.hidden)
  }

  #isVisited () {
    return !!Cookies.get(this.visitedCookieKey)
  }

  #setCookie () {
    Cookies.set(this.visitedCookieKey, true, { path: '/;' })
  }
}
