// IMPORTANT: Update this import to reflect the location of your Stimulus application
// See https://github.com/palkan/view_component-contrib#using-with-stimulusjs
import { application } from '../controllers'

// https://vitejs.dev/guide/features.html#glob-import
const controllers = import.meta.globEager('./**/controller.js')

for (const path in controllers) {
  const module = controllers[path]
  const name = path
    .replace(/\/controller\.js$/, '')
    .replace(/^\.\//, '')
    .replace('_', '-')
    .replace(/\//, '-')
  application.register(name, module.default)
}
