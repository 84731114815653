import { Controller } from '@hotwired/stimulus'
import { useRecaptcha } from '../mixins/useRecaptcha'

export default class extends Controller {
  static targets = ['input']
  static values = {
    action: String
  }

  // Fix infinite submitting via requestSubmit
  isSubmitting = false

  connect () {
    useRecaptcha(this)
  }

  async handleSubmit (event) {
    if (this.isSubmitting) return

    event.preventDefault()

    try {
      const token = await this.recaptcha.getToken(this.actionValue)
      this.recaptcha.setToken(this.inputTarget, token)

      this.isSubmitting = true
      this.element.requestSubmit()
    } catch (e) {
      console.error(e)
    }

    this.isSubmitting = false
  }
}
