import { Controller } from '@hotwired/stimulus'
import { useTogglerBodyScrolling } from '../mixins/useTogglerBodyScrolling'
import { useEmitter } from '../mixins/useEmitter'

export default class extends Controller {
  static targets = ['menu']
  static classes = ['shown', 'hiding']
  isHiding = false

  connect () {
    useTogglerBodyScrolling(this)
    useEmitter(this)
  }

  handleClickToggle (event) {
    event.preventDefault()
    this.#show()
  }

  handleClickClose () {
    this.emit('menu:closeAll')
  }

  handleMenuCloseAll () {
    this.#hide()
    this.enableBodyScrolling()
  }

  handleClickBack (event) {
    event.preventDefault()
    this.#hide()
  }

  handleTransitionEnd () {
    if (!this.isHiding) return

    this.#endHiding()
  }

  #show () {
    this.menuTarget.classList.add(this.shownClass)

    this.disableBodyScrolling()
  }

  #hide () {
    this.#startHiding()
    this.menuTarget.classList.remove(this.shownClass)
  }

  #startHiding () {
    this.isHiding = true
    this.menuTarget.classList.add(this.hidingClass)
  }

  #endHiding () {
    this.isHiding = false
    this.menuTarget.classList.remove(this.hidingClass)
  }
}
