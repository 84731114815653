import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field', 'counter']
  static values = { maxLength: Number }
  static classes = ['invalid']
  isValid = true

  connect () {
    this.#init()
  }

  handleInput (event) {
    const { length } = event.target.value

    this.#setCounter(length)
    this.#checkValidity()
  }

  #init () {
    const valueLength = this.fieldTarget.value.length
    this.#setCounter(valueLength)
    this.#checkValidity()
  }

  #setCounter (length) {
    const counter = this.maxLengthValue - length
    this.counterTarget.textContent = counter

    this.isValid = counter > 0
  }

  #checkValidity () {
    if (this.isValid) {
      this.counterTarget.classList.remove(this.invalidClass)
    } else {
      this.counterTarget.classList.add(this.invalidClass)
    }
  }
}
