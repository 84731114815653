const baseUrl = 'https://www.google.com/recaptcha/api.js?render='
const id = 'recaptcha-v3'

const recaptcha = {
  recaptchaInstance: null,

  async getToken (action) {
    const siteKey = document.querySelector('meta[name=recaptcha-v3-site-key]')?.content

    if (!this.recaptchaInstance) {
      this.recaptchaInstance = await this.load(siteKey)
    }

    const token = await this.recaptchaInstance.execute(siteKey, { action })

    return token
  },

  setToken (input, token) {
    input.value = token
    this.reset()
  },

  load (siteKey) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')
      scriptElement.id = id
      scriptElement.src = baseUrl + siteKey

      scriptElement.onload = () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => resolve(window.grecaptcha))
        }
      }

      scriptElement.onerror = (error) => {
        reject(error)
      }

      document.head.append(scriptElement)
    })
  },

  reset () {
    const scriptElement = document.querySelector(`#${id}`)
    scriptElement?.remove()

    window.grecaptcha = undefined
    this.recaptchaInstance = null
  }
}

export const useRecaptcha = controller => {
  Object.assign(controller, { recaptcha })
}
